// AB test mixin
// Use as function with test id as a parameter:
//
// @include abtest("WEBSITE-123");
//
// Expected setup in DOM:
// <body data-abtest-choosen="WEBSITE-123-CONTROL">
//   ...
//  <p data-abtest="WEBSITE-123-CONTROL">control</p>
//	<p data-abtest="WEBSITE-123-A">A</p>
//	<p data-abtest="WEBSITE-123-B">B</p>


@mixin abtest($testid, $context: body) {
	[data-abtest-chosen="#{$testid}-A"] [data-abtest]:not([data-abtest="#{$testid}-A"]),
	[data-abtest-chosen="#{$testid}-B"] [data-abtest]:not([data-abtest="#{$testid}-B"]),
	[data-abtest-chosen="#{$testid}-C"] [data-abtest]:not([data-abtest="#{$testid}-C"]),
	[data-abtest-chosen="#{$testid}-D"] [data-abtest]:not([data-abtest="#{$testid}-D"]),
	[data-abtest-chosen="#{$testid}-CONTROL"] [data-abtest]:not([data-abtest="#{$testid}-CONTROL"]),
	#{$context}:not([data-abtest-chosen]) [data-abtest]:not([data-abtest$="-CONTROL"]) {
		display: none;
	}
}
