@import "../../bootstrap/functions";
@import "../../bootstrap/mixins";
@import "../variables";
@import "../components/index-svg-orbits";
@import "../mixins";
@import "../components/testimonial";

// AB tests
@import "../mixins/abtest";

// GLOWEB-4790 A/B test
@include abtest('GLOWEB-4790');

// hotfix
/* body {
	overflow-x: hidden;
} */


// AB test GLOWEB-4790
.video-test {
	background: url("../../i/v2/components/bg/background-shapes2.svg") center center no-repeat;
	background-size: cover;
	padding-bottom: $base-unit*5.5;

	@include media-breakpoint-up(lg) {
		padding-bottom: $base-unit*3;
	}

	.hero {
		.hero-right {
			padding: 0;

			@include media-breakpoint-up(lg) {
				padding: 0 $base-unit/2;
			}
		}
	}

	.feat-stripe {
		.container {
			border-radius: none;

			@include media-breakpoint-up(sm) {
				border-radius: $base-unit;
			}

			.video-feat .tagline-large {
				max-width: initial;

				@include media-breakpoint-up(sm) {
					max-width: 200px;
				}
			}
		}
	}

	.featboxes-header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin: $base-unit*4 auto $base-unit*1.5;
	}

	.video {
		position: relative;
		overflow: hidden;
		border-radius: none;

		@include media-breakpoint-up(sm) {
			border-radius: $base-unit*2;
		}

		video {
			width: 101%;
			height: auto;
			display: block;
		}

		.video-btn {
			position: absolute;
			cursor: pointer;
			top: calc(50% - 32px);
			left: calc(50% - 32px);
			transition: all 100ms linear;
			width: 64px;
			height: 64px;
			z-index: 5;

			@include media-breakpoint-up(lg) {
				width: 100px;
				height: 100px;
				top: calc(50% - 50px);
				left: calc(50% - 50px);
			}

			&:hover { transform: scale(1.1); }

			img { width: 100%; }

			&.hide {
				display: none;
			}
		}
	}
}
// /AB test GLOWEB-4790



// New award logos
.hero {
	.hero-subheadline {
		a {
			text-decoration: none;
			border-bottom: 1px dotted;

			&:hover {
				border-bottom: 1px solid;
			}
		}
	}

	.award-badges-wrap {
		.mod-pl-pl & {
			@media (min-width: $breakpoint-custom-lg2) {
				flex-wrap: nowrap;
			}
		}
	}
}
.hero-jp {
	.hero-amoeba {
		display: flex;
		align-items: center;
		img {
			max-width: 600px;
			height: auto;
		}
	}
	.btn-hint {
		margin-top: $base-unit * 1;
	}
}

.hero-content {
	padding-bottom: $base-unit*4;
}


/* customized award section for Avast One locales */
#awards {
	background-color: $orange-faint;
}

.navigation-for-desktop {
	.header {
		&.default {
			z-index: 1000 !important;
		}
	}
}

// cards
.pillars {
	position: relative;
	background-color: $gray10;

	// AB test GLOWEB-4790
	[data-abtest-chosen='GLOWEB-4790-B'].js-pc.mod-en-us &,
	[data-abtest-chosen='GLOWEB-4790-B'].js-pc.mod-en-au &,
	[data-abtest-chosen='GLOWEB-4790-B'].js-pc.mod-en-ca & {
		background-color: $white;
	}
	// /AB test GLOWEB-4790

	.container {
		padding-top: $base-unit * 5;
		padding-bottom: $base-unit * 8;

		@include media-breakpoint-down(md) {
			padding-top: $base-unit * 6;
			padding-bottom: $base-unit * 4;
		}

		.subheader-3d {
			margin-bottom: 40px;

			@include media-breakpoint-down(md) {
				margin-bottom: 1.5 * $base-unit;
			}
		}
	}

	.card {
		@include media-breakpoint-down(md) {
			background-color: $white;
		}
	}
}


// Media objects

// Taglines in media objects as link
.media {
	.title-tagline {
		display: inline-flex;
		align-items: center;
		color: $blue-dark;
		text-decoration: none;

		.inverse & {
			color: $orange-bright;
		}
	}
}

.media-premium,
.media-ultimate {
	background-color: $gray10;

	&--white {
		background-color: $white;
		padding: 8 * $base-unit 0;
	}

	&--transparent {
		background-color: transparent;
	}

	.container {
		background-image: url("../../i/v2/components/bg/bg-blade-dark.svg");

		&--light {
			background-image: url("../../i/v2/components/bg/bg-blade-light.svg");
		}

		&--transparent {
			background-image: none;
		}
	}

	.media {
		padding-top: 6 * $base-unit;
		padding-bottom: 6 * $base-unit;

		@include media-breakpoint-up(lg) {
			padding-top: 8 * $base-unit;
			padding-bottom: 8 * $base-unit;
		}
	}
}

.index-v2-hero-features {
	position: relative;
	margin-top: -$base-unit*4.5;
	margin-bottom: $base-unit*6;
	.row {
		border-radius: $base-unit;
	}
	.hero-feature-stripe {
		flex: 1 1 100%;
		@include media-breakpoint-up(md) {
			flex: 1 1 50%;
		}
		@media (min-width: 1280px) {
			flex: 1 1 25%;
		}
		display: inline-flex;
		align-items: center;
		padding: $base-unit*1;
		span {
			font-size: $base-unit;
			line-height: $base-unit*1.5;
			letter-spacing: 2.5px;
			font-weight: bold;
			text-transform: uppercase;
			padding-left: $base-unit;
		}
	}
}

.index-v2-features {
	margin-bottom: $base-unit*6;
}

.media-premium.pc,
.media-secureline.pc {
	background-color: $white;
}


.a1-logo {
	height: 40px;
	margin-top: 0;
	margin-bottom: $base-unit;
}


.media-avast-one {
	padding-top: $base-unit*6;
	padding-bottom: $base-unit*6;
	background-color: $white;

	@include media-breakpoint-up(lg) {
		padding-top: $base-unit*8;
		padding-bottom: $base-unit*8;
	}

	.container {
		background-image: url("../../i/v2/components/bg/bg-blade-light.svg");
	}

	.media {
		padding-top: $base-unit*6;
		padding-bottom: $base-unit*6;

		@include media-breakpoint-up(lg) {
			padding-top: $base-unit*8;
			padding-bottom: $base-unit*8;
		}
	}
}

.free-reviews {
	padding-bottom: $base-unit*2;

	h2 {
		margin-bottom: $base-unit*3;
		@include media-breakpoint-down(md) {
			margin-bottom: $base-unit*2;
		}
	}

	.award-badges-wrap {
		margin-top: $base-unit*4;
		margin-bottom: $base-unit*4.5;
		@include media-breakpoint-down(md) {
			margin-bottom: $base-unit*2.5;
		}

		.rating {
			margin-top: 0;
		}
	}
}

.mod-ru-ru {
	.media-premium {
		margin-bottom: 7*$base-unit;
	}
}

.media-secureline {
	background-color: $gray10;
}

.media-ultimate .media-image-img-desktop {
	@include media-breakpoint-up(lg) {
		max-width: 75%;
	}
}

/*
.media-3d {
	.list-icons {
		&__item {
			@include media-breakpoint-down(lg) {
				display: block;
			}
		}

		&.list-icons--medium .list-icons__item .list-icons__text {
			@include media-breakpoint-down(md) {
				font-size: $base-unit;
			}
		}
	}
} */

.carousel-slider {
	@media (max-width: $breakpoint-custom-lg1 - 1) {
		padding-bottom: 10rem;
	}
}

.world-security {
	margin-top: -$base-unit * 2.8;
}

img[width="1"][height="1"] {
	position: absolute;
	left: -100vw;
}


//
// Comulative Layout Shift (CLS) fixes
// - if works, should be fullscaled
//
// date: 23. 11. 2021
.mod-en-ww {
	// Buttons are now shown by JS
	// this should be (idealy) solved on Akamai level:
	// - serve buttons by platform / OS (= do not send Windows button to user with MacOS)
	.hero-btn-group {
		height: 120px;

		@include media-breakpoint-up(lg) {
			height: 136px;
		}
	}

	// width and height of the image should be set directly on <img> element
	// this would require:
	// - changes in DS (cmp-hero) - add support for img width and height attributes
	// - change CSS - see https://web-cms.ff.int.avast.com/avast.www/file/edit/13c44840884606667ab67988373b2901
	.hero-amoeba {
		min-height: 480px;
	}
}

#popup_fav_index, #popup_a1_index {
	&.dtyp {
		.dtyp-headline {
			font-size: 56px;
			line-height: 72px;
			font-weight: 800;
			margin-bottom: 48px;
		}
	}
}

// GLOWEB-7157 - fit hero img to viewport in ja-jp
.mod-ja-jp .hero-jp .hero-amoeba img {
	@include media-breakpoint-down(sm) {
		max-width: 100%;
		min-width: 100%;
	}
}
