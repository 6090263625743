.world-security {
	border-radius: 24px 24px 0 0;

	@include media-breakpoint-up(lg) {
		border-radius: 48px 48px 0 0;
	}

	.button-hint {
		margin-top: $base-unit * 2;

		/* a {
			text-decoration: none;
			border-bottom: 1px solid $white;

			&:hover {
				text-decoration: none;
			}
		} */
	}

	.container {
		padding-top: $base-unit * 5;
		z-index: 0;
		position: relative;
		overflow: hidden;

		@include media-breakpoint-down(md) {
			max-width: 100% !important;
		}
		@include media-breakpoint-down(sm) {
			padding-top: $base-unit * 5;
		}
		@include media-breakpoint-up(lg) {
			padding-top: $base-unit * 8;
		}

		.world-security-top {
			margin: 0 auto;

			h1,
			p {
				position: relative;
				z-index: 50;
			}

			p {
				&.subh1 {
					max-width: 633px;
					margin: 0 auto;
				}
			}
		}

		.globe-container {
			position: relative;
			width: 100%;
			z-index: -1;
			display: flex;
			align-content: center;
			justify-content: center;

			@include media-breakpoint-up(md) {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
			@include media-breakpoint-down(sm) {
				margin-top: 72px;
			}
		}

		.globe-static {
			@include media-breakpoint-down(sm) {
				img {
					max-width: 703px;
					display: block;
					position: absolute;
					top: -80px;
					left: 50%;
					transform: translateX(-50%);
				}
			}

			@include media-breakpoint-up(md) {
				display: flex;
				align-items: center;
				justify-content: center;

				position: absolute;
				z-index: 1;
				top: 50%;
				left: 50%;
				width: 100%;
				height: 1401px;
				transform: translate(-50%, -50%);

				img {
					width: 70.65%;
				}
			}
		}

		.world-security-middle {
			position: relative;
			z-index: 5; // must be higher than video and orbits
			width: 90%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			height: 70%;
			min-height: 370px;

			@include media-breakpoint-down(sm) {
				min-height: 550px;
			}
			@include media-breakpoint-up(md) {
				position: absolute;
				width: 100%;
				flex-direction: row;
				transform: translate(-50%, -60%);
				height: auto;
				min-height: auto;
				top: 50%;
				left: 50%;
			}
			@include media-breakpoint-up(lg) {
				width: 92%;
				transform: translate(-50%, -50%);
				left: 50%;
				top: 50%;
			}

			@media screen and (min-width: 993px) and (max-width: 1365px) {
				transform: translate(-50%, -50%);
				top: 50%;
			}
			@media screen and (max-width: 1280px) {
				width: 100%;
			}

			.platform-switch {
				margin-top: 0;
				bottom: auto;

				.btn {
					text-transform: none;
				}

				span {
					//white-space: nowrap;
				}
			}
		}

		.world-security-bottom {
			//width: 100%;
			bottom: -80px;
			left: 0;
			right: 0;
			width: 90%;
			max-width: 800px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			position: absolute;
			z-index: 20;

			@include media-breakpoint-up(lg) {
				bottom: -240px;
			}
			@include media-breakpoint-down(sm) {
				position: relative;
				bottom: 0;
			}
		}

		.info-data {
			text-align: center;
			color: $orange-bright;
			font-weight: 800;
			font-size: 10px;
			letter-spacing: 1.88px;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-transform: uppercase;
			width: 282px;

			@include media-breakpoint-up(lg) {
				font-size: 14px;
				letter-spacing: 2.5px;
			}

			.info-text {
				text-align: center;
				color: $orange-bright;
				font-weight: bold;
				font-size: 10px;
				letter-spacing: 1.88px;
				display: block;

				@include media-breakpoint-down(sm) {
					line-height: 1.8;
				}
				@include media-breakpoint-up(lg) {
					font-size: 14px;
					letter-spacing: 2.5px;
				}
			}

			.info-count {
				display: inline;
				color: $white;
				font-size: $base-unit * 3.125;
				letter-spacing: normal;
				line-height: 1.2;

				@include media-breakpoint-only(lg) {
					font-size: $base-unit * 2.8125;
				}
				@include media-breakpoint-between(md, lg) {
					font-size: $base-unit * 1.7;
				}
				@include media-breakpoint-only(md) {
					font-size: $base-unit * 1.875;
				}
				@include media-breakpoint-between(md, sm) {
					font-size: $base-unit * 1.875;
				}
				@include media-breakpoint-down(sm) {
					font-size: $base-unit * 1.9;
				}
			}

			.info-num {
				display: flex;
				flex-direction: column;
			}
		}

		// Container
		.orbits-container {
			width: 100%;
			z-index: 3;
			left: 0;
			height: 0;
			padding: 0;

			/* reset */
			padding-bottom: calc(100% * .9);
			background-image: url("../../../../i/v2/components/orbits.svg");
			background-size: cover;
			text-align: center;

			@include media-breakpoint-down(sm) {
				background-image: none;
				padding-bottom: 1000px;
			}

			&.animated-svg {
				background: none;
				position: relative;
				top: auto;

				.globe-container .orbits {
					z-index: 1;
					top: 50%;
					left: 50%;
					width: 100%;
					transform: translate(-50%, -50%);

					@include media-breakpoint-down(sm) {
						display: none;
					}
				}
			}
		}

		// SVG
		.orbits {
			margin: auto;
			width: 1200px;
			position: absolute;
			top: -125px;
			left: calc(50% - 600px);

			@include media-breakpoint-down(md) {
				transform: scale(.8);
			}
			@include media-breakpoint-up(lg) {
				top: -160px;
			}
		}

	}

	.info-num[data-animation="fadein"] {
		opacity: 0;
		transform: all;
	}

	[data-animation="fadein"] {
		opacity: 0;
		transform: all;
	}

	.planet {
		fill: $orange-bright;
		filter: url(#dropshadow);
	}

	.space {
		transform-origin: 50% 50%;
	}

	&.animate {
		[data-animation="fadein"] {
			animation: fadein 1s ease-in 1;
			animation-delay: 2s;
			animation-fill-mode: forwards;
		}

		.info-num[data-animation="fadein"] {
			animation: fadein .5s ease-in 1;
			animation-delay: 1.5s;
			animation-fill-mode: forwards;
		}

		.space {
			animation: rotation .5s forwards ease-in;
			animation-delay: 1s;
			opacity: 0;
		}
	}
/*
	.world-security-feature {
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		max-width: 200px;

		&:nth-child(2) {
			margin-top: 130px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 14px;
			max-width: 110px;
		}
	}

	.world-security-feature-icon {
		background-color: #6f56ff;
		width: 58px;
		height: 58px;
		flex: 0 0 58px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0 0 0 8px rgba(112, 85, 255, .5);
		margin-bottom: 30px;

		svg {
			width: 46px;
			height: 46px;

			use {
				fill: $white;
				stroke: $white;
			}
		}

		@include media-breakpoint-down(sm) {
			width: 46px;
			height: 46px;
			flex: 0 0 46px;
			margin-bottom: 20px;

			svg {
				width: 32px;
				height: 32px;
			}
		}
	} */
}

@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
@keyframes rotation {
	0% {
		transform: rotateZ(-100deg);
		opacity: 0;
	}

	100% {
		transform: rotateZ(-178deg);
		opacity: 1;
	}
}

.mod-pt-pt {
	.world-security {
		.container {
			.orbits-container {
				@include media-breakpoint-down(sm) {
					padding-bottom: 1100px;
				}
			}
		}
	}
}
