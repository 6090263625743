// Bootstrap mixins and functions
@import "bootstrap/_mixins";
@import "bootstrap/_functions";

// AOS - Animate on scroll library
//@import "vendor/aos/aos.scss";
@import "vendor/tiny-slider/tiny-slider.scss";

// Cookie Consent - OneTrust
@import "vendor/onetrust/onetrust-avast-floating-center.scss";


// Avast variables and mixins
@import "avast/_variables";

// Bootstrap
@import "bootstrap/bootstrap";

// Custom Mixins
@import "avast/_mixins";

// Components
@import "avast/components/grid";
@import "avast/components/type";
@import "avast/components/badge";
@import "avast/components/elevation";
@import "avast/components/platform-switcher";
@import "avast/components/hero";
@import "avast/components/hero-left-amoeba";
@import "avast/components/button";
@import "avast/components/button-circle";
@import "avast/components/card";
@import "avast/components/notice";
@import "avast/components/feature-item";
@import "avast/components/articles";
@import "avast/components/media";
@import "avast/components/carousel-slider";
@import "avast/components/award-badges";
@import "avast/components/social-icons";
@import "avast/components/product-icon";
@import "avast/components/cookie-bar";
@import "avast/components/message-bar";
@import "avast/components/unsupported-browser-message";
@import "avast/components/icon";
@import "avast/components/chrome-banner";
@import "avast/components/dtyp";
@import "avast/components/modal";
@import "avast/components/notification-wrong-download";
@import "avast/components/rating";
@import "avast/components/email-capture";
@import "avast/components/colors";

// Layout
// @import "avast/layout/font-face"; removed from global styles
@import "avast/layout/header";
@import "avast/layout/footer";
@import "avast/layout/region-flags";


// Page specific CSS
// previously linked in snippet "index_header-files"
// linked here to improve page loading speed
// combining CSS will reduce number of render-blocking resources
@import "avast/pages/index";



// Smooth scroll
html {
	scroll-behavior: smooth;
}
